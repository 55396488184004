.typography-label {
    color: "#8083A3";
    font-size: "14px";
    font-family: "Inter";
    font-weight: 700;
    margin-bottom: "10px";
}

.custom-autocomplete-textfield .MuiInput-underline:before {
    border-bottom: 1px solid #e4e6e8;
}

.custom-autocomplete-textfield .MuiInput-underline:after {
    border-bottom: 1px solid #e4e6e8;
}

.custom-autocomplete-textfield .MuiInputBase-input {
    font-size: 14px;
    color: #8083A3;
    font-family: 'Inter', sans-serif;
}

.custom-autocomplete-textfield input {
    padding: 0 !important;
    padding-top: 5px !important;
    color: #8083A3;
}

.custom-autocomplete-textfield input:hover {
    border: none !important;
}

.custom-autocomplete-textfield>div:before {
    border-bottom: 1px solid #e4e6e8 !important;
}

.custom-autocomplete-textfield>div:after {
    border: none !important;
}

.custom-autocomplete-textfield input+div {
    top: unset !important;
    margin-top: 12px !important;
}

.custom-autocomplete-textfield input+div>button svg {
    position: unset !important;
    margin-right: 0px !important;
}