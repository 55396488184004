/* ============= CSS ============= */
.underline-on-hover:hover {
  text-decoration: underline;
}

.resetLink-button {
  color: #fff !important;
     border-radius: 19px !important;
     width: 200px !important;
     height: 43px !important;
     background-color:  #EC952D !important;
    overflow:hidden !important;
    cursor:pointer !important
}

.resetLink-button:disabled span {
  cursor: not-allowed !important;
  background-color:  #ffc176 !important;
  
}


.resetLink-button:disabled {
  background-color:  #ffc176 !important;
}

#cancel {
  background-color: #ec952d;
}