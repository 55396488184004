.MuiTabs-indicator {
    background-color: transparent;
  }
  
  .rounded-input .MuiOutlinedInput-root {
    border-radius: 35px;
  }
  
  #freelancer-search-id-dot{
    padding-left: 5px;
    padding-bottom: 5px;
  }


  .freelancer-search-tab:nth-child(1) .MuiTab-wrapper {
    width: 165px !important;
  }
  
  .freelancer-search-tab span {
    text-transform: capitalize;
    font-weight: 600;
  }
  .tabLabels .MuiTabs-scroller .MuiTabs-flexContainer button{
    padding:9px 19px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    min-width:auto !important;
    min-height:auto !important;
  }
  .allTabs .MuiTabs-scroller .MuiTabs-flexContainer button{
    min-width:auto !important;
  }

  .freelancer-search-primary-button span {
    background-color: transparent !important;
    border-radius: 23px !important; 
    padding: 0 !important;
    font-size: 14px ;
    min-width: 0 !important;
    display: inherit !important;
    margin: 0;
    font-weight: 500;
    text-transform: capitalize;
}
.freelancer-search-primary-button span svg{
  color: #ffffff;
}
.freelancer-search-secondary-button span {
  background-color: transparent !important;
  border-radius: 23px !important; 
  padding: 0 !important;
  font-size: 14px ;
  min-width: 0 !important;
  display: inherit !important;
  margin: 0;
  color: black;
  font-weight: 500;
  text-transform: capitalize;
}

.freelancer-search-secondary-button span svg{
 height: 14px;
}

.input-box input{
  padding: 0px 15px !important;
  height:58px;
  width:790px;
  color:#8083A3 !important;
}

.header-name{
justify-content: space-between;
display: flex;
align-items: center;
}

.avb-btn{
  text-transform: capitalize;
  font-size: 10px !important;
  font-weight: 600;
}
.avb-btn span{
  text-transform: capitalize;
  font-size: 10px ;
  font-weight: 600;
  color: #00A7A2 
}

.name-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
}
.freelancer-search-name{
  font-weight: 600;
  font-size: 18px;
}

.name-card span button {
  box-shadow: none;
  font-size: 0.9rem;
  text-transform: capitalize;
  margin-left: 15px;
}
.freelancer-search-skill,.freelancer-search-location{
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
  flex-basis: content !important;

}

.freelancer-search-ratings{
  display: flex;
  align-items: center;
  font-size: 12px;
}
.fav-btn, .msg-btn {
  width: 45px;
  height: 45px;
}
.freelancer-search-user-online{
  
    position: absolute;
    top: 60px;
    right: 3px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: green
  
}

.freelancer-search-load-more-grid{
  display: flex;
  justify-content: center;
}

.freelancer-search-ArrowForwardIosIcon{
  height: 14px
}
.freelancer-search-card-action{
  justify-content: end;
}

#freelancer-search-name-grid{
  flex-grow: 0;
  flex-basis: auto;
}

.freelancer-search-chip{
  background-color: #F6F7F7;
  margin: 5px 5px 5px 0px;

}

.freelancer-search-button {
  background-color: #00A7A2;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.freelancer-search-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}







.profileAvatar{
  position: relative;
width: 80px;
height: 80px;
margin: 0 auto;
}
.avatarActive{
    height: 80px;
    width: 80px !important;
    border-radius: 50%;
    object-fit: cover;
    margin: 0 auto;
    position: relative
  
}


.skillSet span {
  padding: 0 10px 0 10px;
  border-right: 1px solid #000;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}
.skillSet span:last-child {
  border-right: 0;
}
.skillSet span:first-child {
  padding-left: 0;
}
.skillSetCard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.freelancer-search-location {
  position: relative;
  padding: 1px 0 0 15px;
}
.freelancer-search-location:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #8083A3;
  position: absolute;
  border-radius: 10px;
  left: 0;
  top: 7px;
}
.freelancer-search-location span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Responsive styles */





.freelancer-search-success-2 {
  font-weight: 600;
  color: black;
  font-size: 30px
}

.freelancer-search-success-3 {
  font-weight: 600;
  font-size: 20px;
}

.freelancer-search-success-4 {
  font-size: 16px;
}

.freelancer-search-success-5 {
  margin: 8px;
}

.freelancer-search-success-primary-button span {
  background-color: transparent !important;
  border-radius: 23px !important; 
  padding: 0 !important;
  font-size: 14px ;
  min-width: 0 !important;
  display: inherit !important;
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
}
.freelancer-search-success-primary-button span svg{
color: #ffffff;
}
.freelancer-search-success-secondary-button span {
background-color: transparent !important;
border-radius: 23px !important; 
padding: 0 !important;
font-size: 14px ;
min-width: 0 !important;
display: inherit !important;
margin: 0;
color: black;
font-weight: 600;
text-transform: capitalize;
}


/*  proposal Hire Free Lancer*/


.freelancer-search-proposal-success-primary-button span {
  background-color: transparent !important;
  border-radius: 23px !important; 
  padding: 0 !important;
  font-size: 14px ;
  min-width: 0 !important;
  display: inherit !important;
  margin: 0;
  font-weight: 600;
  text-transform: capitalize;
}
.freelancer-search-proposal-success-primary-button span svg{
color: #ffffff;
}
.freelancer-search-proposal-success-secondary-button span {
background-color: transparent !important;
border-radius: 23px !important; 
padding: 0 !important;
font-size: 14px ;
min-width: 0 !important;
display: inherit !important;
margin: 0;
color: black;
font-weight: 600;
text-transform: capitalize;
}

@media(max-width: 768px) {
  .input-box input {
    height: 40px;
    font-size: 15px;
  }
  .input-box button {
    height: 35px !important;
    width: 35px !important;
  }
}
@media(max-width: 420px) {
  .input-box input {
    height: 40px;
    font-size: 12px;
  }
}
@media(max-width: 320px) {
  .input-box input {
    height: 40px;
    font-size: 8px;
  }
}