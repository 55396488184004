
a:focus {
    text-decoration: none;
}

.not_main_box .MuiTypography-h6 {
    font-size: 14px;
}

.not_main_box .hourtag {
    font-size: 14px;
    white-space: nowrap;
}

.not_main_box span.MuiButton-label {

    border-radius: 18px;
    text-transform: none;
    font-size: 12px;
}

.MuiBox-root.MuiBox-root-1.notall {
    width: 28rem !important;
}

.not_main_box .MuiPaper-root.MuiCard-root.MuiPaper-elevation1 {
    box-shadow: none;
}

.not_main_box .MuiButton-containedSizeLarge {
    padding: 0px 4px;
}

.not_main_box .MuiCardContent-root {
    padding: 16px 16px 0px 16px;
}

.not_main_box .designtwo span.MuiButton-label {
    background-color: #f2f2f2;
    color: #000;
    display: inherit;
    font-weight: 700;

}

.notall .designtwo span.MuiButton-label {
    background-color: #f2f2f2;
    color: #000;
}

.not_main_box .designone span.MuiButton-label {
    background-color: #00a7a2;
    color: #fff;

}

.notall .designone span.MuiButton-label {
    background-color: #00a7a2;
    color: #fff;
}

.notall span.MuiButton-label {

    padding: 5px 5px;
    min-width: 80px;
    text-transform: none;
    font-size: 12px;
}

.notificationCard .MuiCardContent-root {
    padding: 15px;
    box-sizing: border-box;
}

.notificationCard .MuiCardContent-root:last-child {
    padding-bottom: 15px;
}

.notifications-page .middle-container {
    min-height: 50vh;
}

.custom-header .header-main {
    box-shadow: 0 6px 20px #ecedef;
}

.custom-header .footer-main {
    border-top: 1px solid #ecedef;
}

.custom-header .footer-main .MuiGrid-item.footeMain-grid {
    margin-top: 0 !important;
    padding-top: 50px;
}

.cardbottom {
    margin-top: 1px;
    border-bottom: 2px solid #eee;
}

.cardbottom:last-child {
    margin-top: 1px;
    border-bottom: 0;
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
    padding: 0;
}
a:hover{
    text-decoration: none;
}

.htmlcontent .p {
    padding: 0px;
    margin-bottom: 10px;
}

.notification-section {
    position: relative;
}
#lancer p span {
    color: #f99d1c;
    font-weight: 700;
}
#sponser p span {
    color: #00a7a2;
    font-weight: 700;
}
.resNotificationCardMain {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    justify-content: space-between;
}
.resNotificationCardTop, .resNotificationCardBottom{
    display: flex;
    gap: 20px;
}
@media(max-width: 990px) {
    .not_main_box {
        max-width: 90% !important;
    }
}
@media (max-width: 560px) {
    
    .resNotificationCardMain, .resNotificationCardTop {
        flex-direction: column;
    }
    .resNotificationCardBottom {
        width: 100%;
        justify-content: space-between;
    }
    
}

  @media(max-width: 420px) {
    .text-center{
       text-align: center;
    }
}
