/* ============= Theme CSS ============= */

.main-wrapper  {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: #8083a3;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 22px;
}

h6 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 0 0 10px;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 20px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.header-right > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}

.header-right ul li a {
  color: #8083a3;
}

button.dropdown-btn {
  color: #8083a3;
  text-transform: lowercase;
  font-size: 14px;
  font-weight: 400;
  padding: 0 20px;
}

button.dropdown-btn:hover {
  color: #8083a3;
}

.dropdown-btn,
.dropdown-btn:focus,
.dropdown-btn:hover,
.dropdown-btn:active,
.dropdown-menu {
  box-shadow: none;
}
.box-border {
  border-left: 1px solid #e4e6e8;
  border-right: 1px solid #e4e6e8;
  border-bottom: 1px solid #e4e6e8;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}
span.id-text {
  background: #045372;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-right: 5px;
  text-transform: uppercase;
  text-align: center;
}

/* ============== Account Type CSS ============== */

.inner-block {
  max-width: 800px;
  margin: auto;
}

.inner-block h3 {
  font-weight: 400;
  color: #000;
  margin: 35px 0;
}

.step-block ul {
  counter-reset: my-sec-counter;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.step-block ul li {
  position: relative;
  display: flex;
  align-items: center;
}

.step-block ul li::before {
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter);
  background: #ec952d;
  font-size: 14px;
  color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: inline-block;
  line-height: 26px;
  margin-right: 15px;
  cursor: pointer;
  opacity: 0.5;
}

.step-block ul li svg {
  margin-left: 20px;
}

.step-block ul li.active::before,
.step-block ul li.active a {
  opacity: 1;
}

.step-block ul li a {
  color: #000;
  opacity: 0.5;
}

.profile-block {
  border: 1px solid #e4e6e8;
  border-radius: 18px;
  max-width: 100%;
  background-color: #fff;
  margin: 1;
  margin-bottom: 60px;
}

.profile-heading {
  padding: 24px 0;
  text-align: center;
  border-bottom: 1px solid #e4e6e8;
}

.profile-heading h6 {
  font-weight: 400;
  color: #000;
  margin: 0;
}

.profile-block-wrapper {
  display: flex;
  margin: 0 -15px;
}

.profile-item {
  width: 50%;
  padding: 0 15px;
}

.profile-block.active .inner-profile-block {
  padding: 36px 25px;
  border: 1px solid #ec952d;
  border-top: 5px solid #ec952d;
}

.profile-block.active .profile-logo img {
  filter: grayscale(0);
  opacity: 1;
}

.inner-profile-block {
  padding: 36px 26px;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #e4e6e8;
  border-radius: 23px;
  border-top: 5px solid #e4e6e8;
}

.profile-logo img {
  filter: grayscale(1);
}

.profile-content {
  padding-top: 30px;
}

.profile-content p {
  margin: 0;
}

.profile-content h5 {
  margin: 0;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.profile-content h5 img {
  margin-left: 10px;
}

.profile-content a {
  color: #000;
}

.profile-block-nav ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.profile-block-nav button {
  color: #8083a3;
  border: 1px solid #e4e6e8;
  border-radius: 23px;
  padding: 10px 30px;
  display: inline-block;
  background-color: #fff;
}

.profile-block-nav ul li button.current {
  border: 1px solid #ec952d;
  color: #000;
}

.button-block {
  text-align: center;
  padding: 30px 0;
  border-top: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button {
  border-radius: 23px;
  padding: 12px 30px;
  min-width: 140px;
  color: #000;
  display: inline-block;
  font-size: 14px;
  /* margin: 0 15px; */
  border: none;
  font-weight: 700;
}

.btn-orange {
  background: #ec952d;
  color: #fff;
}

.button:hover {
  color: #000;
}

.btn-orange:hover {
  color: #fff;
}

.img-upload-block {
  text-align: center;
  padding: 30px;
  /* margin: 0 0 30px; */
  border-bottom: 1px solid #e4e6e8;
  background-color: #ec952d;
  border-radius: 18px 18px 0 0;
}

.sponsor-background-color {
  background-color: #00A7A2 !important;
  color: #f2f2f2 !important;
}

.img-upload-block h6 {
  color: #fff;
  font-weight: 700;
  margin: 0;
  font-size: 18px;
  font-family: "Inter";
}

.drop-zone-block {
  width: 110px;
  height: 110px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.drop-zone-block:after {
  content: "";
  border: 2px dashed #fff;
  width: 120px;
  height: 120px;
  line-height: 120px;
  border-radius: 50%;
  position: absolute;
  top: -7px;
  left: -7px;
}

.drop-zone-block p {
  margin: 0;
  line-height: 15px;
  color: #000;
  font-size: 12px;
}

.img-upload-block p {
  color: #fff;
  font-weight: 500;
  margin: 0;
  font-size: 12px;
  font-family: "Inter";
  margin-top: 10px;
}

.upload-photo-btn .MuiButton-label {
  margin-top: 20px;
  background-color: #f2f2f2;
  border-radius: 23px;
  padding: 5px 15px;
  min-width: 140px;
  color: #000;
  display: inline-block;
  font-size: 14px;
  border: none;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Inter";
}

.row {
  display: flex;
  margin: 0;
  padding: 0 30px;
  gap: 20px;
}

.col-50 {
  width: 50%;
}

label {
  display: block;
  margin-bottom: 10px;
}

.form-block {
  padding: 0 15px;
}

.field-group {
  margin-bottom: 25px;
  position: relative;
}

.field-group2 {
  margin-bottom: 25px;
  position: relative;
}

.field-group3 {
  margin-bottom: 25px;
  position: relative;
}
input,
select,
textarea {
  border: none;
  border-bottom: 1px solid #e4e6e8;
  color: #8083a3;
  width: 100%;
  min-height: 36px;
  background: none;
}

input::placeholder,
textarea::placeholder {
  color: #8083a3;
  opacity: 1 !important;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

textarea {
  resize: none;
}

.phone-block {
  display: flex;
}

select#number {
  max-width: 65px;
}

input#number {
  padding-left: 10px;
}

#image {
  max-width: 100%;
  max-height: 100%;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  top: -10px;
}

.upload-btn-wrapper .button {
  border: none;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

select {
  appearance: none;
  -webkit-appearance: none;
  position: relative;
}

.field-group svg {
  position: absolute;
  /* top: 30px; */
  /* right: 20px; */
}

.field-group2 svg {
  position: absolute;
  /* top: 30px; */
  right: 20px;
}
.field-group3 svg {
  position: absolute;
  /* top: 30px; */
  right: 20px;
}

input[type="search"] {
  background-position: right;
  cursor: pointer;
}

.upload-btn {
  position: absolute;
  top: 20px;
  right: 0;
  border-bottom: 1px solid #e4e6e8;
  width: 100%;
  text-align: right;
}

.block-title {
  padding-top: 15px;
}

.block-title h6 {
  font-weight: 400;
  color: #000;
}

.block-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e6e8;
  padding-bottom: 8px;
  margin-bottom: 25px;
  color: #000;
}

.block-content p {
  margin: 0;
}

span.link {
  color: #ec952d;
}

.w-50 {
  width: 50%;
}

.checkbox-round {
  max-width: 20px;
  min-height: 20px;
  background-color: white;
  border-radius: 50%;
  vertical-align: top;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: gray;
}

label.checkbox {
  display: inline-block;
  padding-left: 10px;
  cursor: pointer;
}

.d-flex {
  display: flex;
  gap: 30px;
}

.field-group label {
  font-weight: 700;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  color: #8083A3;
}

.field-group2 label {
  font-weight: 700;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  color: #8083A3;
  padding-top: 8px;
}

.field-group3 label {
  font-weight: 700;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  color: #8083A3;
  padding-top: 7px;
}

.field-group input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8083a3;
}
.field-group2 input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8083a3;
}
.field-group3 input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #8083a3;
}

.upload-image-text {
  font-family: "Inter";
  font-weight: 700 !important;
  font-size: 10px !important;
  color: #222222 !important;
  margin: 5px !important
}

.size-of-image {
  font-family: "Inter";
  font-weight: 500;
  font-size: 8px !important;
  color: #8083a3 !important;
}

.field-group {
  padding: 15px;
  color: #8083a3;
  margin-top:20px !important;
}

.field-group2 {
  padding: 15px 15px;
  color: #8083a3;
  margin-top: 19px !important;
}

.field-group3 {
  padding: 15px 15px 15px;
  color: #8083a3;
  margin-top:20px !important;
}
.field-classes {
  font-family: "Inter";
  font-weight: 700;
  font-size: 14px;
  color: #8083a3;
}

.error-class {
  font-family: "Inter";
  font-weight: 500;
  font-size: 12px;
  color: #d90000;
  margin-top: 10;
}

.phone-number-field {
  display: flex;
  margin-top: 14px;
  align-items: center;
}

.selected-element {
  font-family: "Inter";
  font-weight: 700;
  font-size: 14px;
  color: #8083a3;
}

.MuiSnackbar-root {
  font-family: Inter;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background: #16a700;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25098);
  border-radius: 32px;
}

.mobile-number-field {
  margin-top: 14px;
}
.react-tel-input .flag-dropdown {
  background-color: #fff !important;
  border: none !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #e4e6e8 !important;
}

.textfield-group {
  margin-bottom: 25px;
  position: relative;
  padding: 10px;
  color: #8083a3;
  margin-top:15px !important;
}


.textfield-group input::placeholder {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #8083a3;
}
.textfield-group label {
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter";
  font-style: normal;
  color: #8083A3;
  padding-left: 0;
}

.textfield-group svg {
  position: absolute;
  right: 20px;
  padding:5px;
}

@media(max-width: 767px) {
  .form-block .row{
    display: block;
    margin: 0;
  }
  .form-block .col-50 {
    width: 100%;
    padding: 0;
  }
  .header-right > ul {
    justify-content: center;
  }
}

@media(max-width: 640px) {
  .upload-btn {
    position: relative;
  }
 }

@media(max-width: 599px) {
  .field-group3{
    margin-top: 0px !important;
  }
}

@media(max-width: 420px) {
  .form-block .row {
    padding: 0 15px;
  }
  .header-right > ul {
    gap: 0;
  }

  .field-group {
    margin: 10px 0px !important;
    padding: 0px;
    position: relative;
  }

  .field-group2 {
    margin: 10px 0px !important; 
    padding: 0px;
    position: relative;
  }

  .field-group3 {
    margin: 10px 0px !important;
    padding: 0px;
    position: relative;
  }
}

@media(max-width: 375px) {
  .button-block div{
    width: 100%;
  }
  .button-block button  {
    width: 90%;
    min-width: 90% !important;
    margin: 0 !important;
  }
}

@media(max-width: 320px) {
  .step-block ul {
    justify-content: start;
    align-items: start;
    margin-left: 20%;
  }
}

.typography-label {
  color: "#8083A3";
  font-size: "14px";
  font-family: "Inter";
  font-weight: 700;
  margin-bottom:"10px";
}

.custom-autocomplete-textfield .MuiInput-underline:before {
  border-bottom: 1px solid #e4e6e8; /* Greyish underline */
}

.custom-autocomplete-textfield .MuiInput-underline:after {
  border-bottom: 1px solid #e4e6e8; /* Greyish underline */
}

.custom-autocomplete-textfield .MuiInputBase-input {
  font-size: 14px;
  color: #8083A3;
  font-family: 'Inter', sans-serif;
}

.custom-autocomplete-textfield input {
  padding: 0 !important;
  padding-top: 5px !important;
  color: #8083A3;
}

.custom-autocomplete-textfield input:hover {
  border: none !important;
}

.custom-autocomplete-textfield > div:before {
  border-bottom: 1px solid #e4e6e8 !important;
}

.custom-autocomplete-textfield > div:after {
  border: none !important;
}

.custom-autocomplete-textfield input + div {
  top: unset !important;
  margin-top: 12px !important;
}

.custom-autocomplete-textfield input + div > button svg {
  position: unset !important;
}