.projectsearch-bar button:nth-child(2) {
    transform: scale(1);
}

.projectsearch-bar button:nth-child(1) {
    margin-right: 20px;
}

.projectchips-block {
    margin: 30px 0 0;
}

.projectchips-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.projectfilter-select .MuiSelect-select.MuiSelect-select {
    padding: 5px 0;
}

.projectfilter-select {
    background: url("../downarrow.svg") no-repeat right center;
    background-size: 3%;
}

.projectfilter-select svg {
    display: none;
}

.projectsearch-bar button:nth-child(2) {
    right: 7px;
}

.projectchips-heading svg {
    border: 1px solid;
    border-radius: 50%;
}

.projectfilter-main {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 20px;
}

.projectfilter {
    width: calc(50% - 20px);
    color: #8083a3;
}

.projectfilter-select {
    width: 100%;
}

.projectfilter-select fieldset {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 0;
}

.projectchips-container {
    display: flex;
    gap: 4px;
    margin: 15px;
    overflow: auto;
    padding-bottom: 7px;
}

.projectinput-container {
    display: flex;
    max-width: 450px;
    width: 100%;
    position: relative;
}

.projectsearch-bar {
    box-shadow: none !important;
    position: relative;
    display: block !important;
}

.projectsearch-bar input {
    border: 1px solid #E4E6E8;
    border-radius: 31px;
    box-shadow: none;
    padding: 10px 10px 10px 25px;
}

.projectsearch-bar button {
    background: #00A7A2;
    position: absolute;
    right: 60px;
    bottom: -5px;
}
/* .projectsearch-bar .jss7 {
    width: 100%;
    margin: 0 0px 0px 0;
} */

.projectsearch-bar .jss4 {
    margin-right: 0;
}
.projectsearch-bar button:hover {
    background: #00A7A2;
}

.projectmodal-button {
    background-color: rgb(0, 167, 162);
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    list-style: none;
    font-weight: 700;
    border-radius: 30px;
    color: rgb(255, 255, 255);
    padding: 7px 25px;
    border: 1px solid rgb(0, 167, 162);
}

.projectmodal-div {
    gap: 10px;
    justify-content: end;
    display: flex;
    width: 100%;
}

.projectfilterbutton {
    /* margin-left: 20px; */
    background: #00A7A2;
    color: #fff;
    padding: 15px 30px;
    border-radius: 32px;
    border: none;
    font-size: 14px;
    font-weight: 700;
    min-width: 136px;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
}

.projectfilterbutton img {
    width: 25px;
}
.projectloading-main {
    position: relative;
    height: 100px;
}
.dashboardBtn {
    display: flex;
    gap: 30px;
    margin-top: 20px;
}
.emptyDraftPage {
    display: flex;
    padding: 10px 0 30px 0;
    align-items: center;
    justify-content: center;
}
.projectloading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


/* .project-search .jss7, .project-search .ForwardRef-searchContainer-15 {
    width: 98% !important;
    margin: auto 0 !important;
} */
.projectinput-container .MuiPaper-elevation1 {
    margin-right: 8px;
}
@media(max-width: 480px) {
    .dashboardBtn {
        flex-direction: column;
        gap: 10px;
    }
}
